import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import favicon from "../images/favicon.ico";

import "./index.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Pop from "../components/Pop";

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet
      title="ヒラハタクリニックの健康診断"
      meta={[
        {
          name: "description",
          content:
            "健康診断・人間ドックは東京渋谷駅前のヒラハタクリニックへ。夜間の受診も可能です",
        },
        { name: "keywords", content: "健康診断,人間ドック,東京,渋谷,秋葉原" },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, shrink-to-fit=no",
        },
      ]}
      link={[{ rel: "icon", href: favicon }]}
    />
    <Header />
    <div>
      <div style={{ margin: "0 0 50px 0" }}>{children}</div>
      <Pop />
      <Footer />
    </div>
  </div>
);

TemplateWrapper.propTypes = {
  children: PropTypes.func,
};

export default TemplateWrapper;
