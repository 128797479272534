import React from 'react';
import { Link } from 'gatsby';
import {FaPhone} from 'react-icons/fa';
const style = {
  btn: {
    borderRadius: 0,
    margin: 5,
  },
};

class Pop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      innerWidth: 0,
    };
  }
  componentDidMount() {
    this.setState({
      innerWidth: window.innerWidth,
    });
  }
  render() {
    const { innerWidth } = this.state;
    const isMobile = innerWidth < 500;
    const positionStyle = isMobile
      ? { bottom: 0, right: 0, width: innerWidth }
      : { bottom: 40, right: 40 };
    const flexStyle = isMobile ? 'justify-content-around' : 'flex-column';
    return (
      <div
        style={{
          ...positionStyle,
          backgroundColor: 'rgba(0,0,0,0.1)',
          position: 'fixed',
        }}
      >
        <div
          className={`d-flex align-items-center ${flexStyle}`}
          style={{ padding: 10 }}
        >
          <a
            href="tel:0364197056"
            style={style.btn}
            className="btn btn-success"
            role="button"
          >
            <FaPhone style={{ fontSize: 20, color: '#fff' }} />
            03-6419-7056
          </a>
          <Link
            to="/about-us/contact"
            style={style.btn}
            className="btn btn-light"
            role="button"
          >
            フォームで問合せ
          </Link>
        </div>
      </div>
    );
  }
}

export default Pop;
