import React from "react";
import { Link } from "gatsby";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
} from "reactstrap";

const menus = [
  {
    title: "健康診断について",
    linkList: [
      { title: "当院の特徴", link: "/health-checking" },
      {
        title: "どこまで診断すればよいか",
        link: "/health-checking/about-course",
      },
      {
        title: "健康診断をお考えの企業様へ",
        link: "/health-checking/for-companies",
      },
      { title: "Q&A", link: "/health-checking/qa" },
    ],
  },
  { link: "/fee2024", title: "コース・費用" },
  { link: "/flow", title: "健康診断の流れ" },
  {
    title: "当院について",
    linkList: [
      { title: "医院紹介", link: "/about-us" },
      { title: "ブログ", link: "/blogs" },
      { title: "使用する設備の紹介", link: "/about-us/equipments" },
      { title: "お問い合わせ", link: "/about-us/contact" },
    ],
  },
];

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      navbarCollapsed: true,
    };
    menus.forEach((menu, index) => {
      this.state[`dropdownOpen${index}`] = false;
    });
  }

  toggle = (menuIndex) => () => {
    this.setState({
      [`dropdownOpen${menuIndex}`]: !this.state[`dropdownOpen${menuIndex}`],
    });
  };
  toggleNavbar() {
    this.setState({
      navbarCollapsed: !this.state.navbarCollapsed,
    });
  }
  render() {
    return (
      <Navbar
        color="faded"
        light
        expand="lg"
        sticky="top"
        fixed="top"
        style={{ backgroundColor: "white" }}
      >
        <div className="container">
          <Link
            to="/"
            onClick={() =>
              this.setState({
                navbarCollapsed: true,
              })
            }
            className="navbar-brand"
          >
            ヒラハタクリニック
          </Link>
          <NavbarToggler onClick={this.toggleNavbar} />
          <Collapse isOpen={!this.state.navbarCollapsed} navbar>
            <Nav className="ml-auto" navbar>
              {menus.map((menu, menuIndex) =>
                menu.link ? (
                  <NavItem key={menu.title}>
                    <Link
                      to={menu.link}
                      onClick={() =>
                        !this.state.navbarCollapsed &&
                        this.setState({
                          navbarCollapsed: true,
                        })
                      }
                      className="nav-link"
                      style={{
                        display: "inline-block",
                        paddingLeft: "0.75rem",
                        paddingRight: "0.75rem",
                      }}
                    >
                      {menu.title}
                    </Link>
                  </NavItem>
                ) : (
                  <NavItem key={menu.title}>
                    <ButtonDropdown
                      isOpen={this.state[`dropdownOpen${menuIndex}`]}
                      toggle={this.toggle(menuIndex)}
                    >
                      <DropdownToggle
                        caret
                        color="muted"
                        style={{ backgroundColor: "white" }}
                      >
                        {menu.title}
                      </DropdownToggle>
                      <DropdownMenu>
                        {menu.linkList.map((sub) => (
                          <DropdownItem key={sub.title}>
                            <Link
                              to={sub.link}
                              onClick={() =>
                                !this.state.navbarCollapsed &&
                                this.setState({
                                  navbarCollapsed: true,
                                })
                              }
                            >
                              {sub.title}
                            </Link>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </ButtonDropdown>
                  </NavItem>
                )
              )}
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    );
  }
}

export default Header;
