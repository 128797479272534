import React from 'react';

const Footer = () => (
  <div style={{ backgroundColor: '#00403a' }}>
    <div
      className="d-flex justify-content-center align-items-center flex-wrap"
      style={{ padding: '80px 0', color: 'white' }}
    >
      <a href="https://www.hirahata-clinic.or.jp/"  style={{ color: 'white' }}>
        <div>東京 渋谷駅前　医療法人社団創友会　ヒラハタクリニック</div>
      </a>
    </div>
  </div>
);

export default Footer;
